import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeftRounded";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRightRounded";
import { IconButton, styled, useTheme } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { translationKeys } from "../../translations/main-translations";

const Root = styled("div")(({ theme }) => ({
    flexShrink: 0,
    marginLeft: theme.spacing(2),
}));

interface IPaginationActionsDense {
    minPage?: number;
    offset?: number; // offset of pages to show in each direction from current page
    // Below are TablePaginationActionsProps, their interface is not exported by mui
    onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
    page: number;
    count: number;
    rowsPerPage: number;
}
const PaginationActionsDense = ({ minPage = 0, onPageChange, page, count, rowsPerPage }: IPaginationActionsDense) => {
    const theme = useTheme();
    const { t } = useTranslation();

    const selectPage = (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => onPageChange(event, newPage);

    const maxPage = count == null || count <= 0 ? 0 : Math.floor((count - 1) / rowsPerPage);

    return (
        <Root>
            <IconButton
                size="small"
                onClick={(e) => selectPage(e, page - 1)}
                disabled={page <= minPage}
                aria-label={t(translationKeys.VDLANG_PAGINATION_PREVIOUS_PAGE)}
            >
                {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                size="small"
                onClick={(e) => selectPage(e, page + 1)}
                disabled={page >= maxPage}
                aria-label={t(translationKeys.VDLANG_PAGINATION_NEXT_PAGE)}
            >
                {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
        </Root>
    );
};

export default PaginationActionsDense;
